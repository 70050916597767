/* @import "src/app/assets/css/global.css"; */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Andriod/IOS styling starts */

.andriod-page-wrapper {
  position: fixed;
  top: 30px;
  left: 0;
  width: 100%;
  text-align: center;
}

.qr-code-wrap {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  margin-bottom: 13%;
  border-radius: 4px;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.5);
}

.qr-code-wrap img {
  border-radius: 4px;
}

.hotel-details-wrap {
  height: 108px;
  width: 290px;
  margin: 0 auto;
  border-radius: 4px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background: #f4f4f7;
}

.title {
  color: #000000;
  font-family: Poppins;
  font-size: 9px;
  font-weight: 600;
  letter-spacing: 0.9px;
  line-height: 13px;
  text-transform: uppercase;
  display: block;
}

.room-details {
  color: #000000;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: capitalize;
  display: block;
}

.about-hotel {
  color: #000000;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: capitalize;
  display: block;
}

.add-screen-wrap {
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  height: 60px;
  width: 288px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
}

.screen-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail-img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  background-color: #29262c;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-wrapper-custom .thumbnail-img img {
  height: 33.33px;
  width: 33.76px;
}

.add-text {
  color: #333333;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}

.add-btn {
  color: #3f853f;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.43px;
  line-height: 18px;
}

.blurredBg {
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

.header {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.accept-btn {
  width: 100%;
  border-radius: 4px;
  background-color: #ffd865;
  color: #333;
  font-weight: 600;
  padding: 15px 38px;
}

.accept-btn-disabled {
  width: 100%;
  border-radius: 4px;
  background-color: #e2e2e2;
  color: rgb(180, 180, 180);
  font-weight: 600;
  padding: 15px 38px;
}

.popup-content {
  width: 75% !important;
  padding: 2.5rem !important;
  border-radius: 4px;
}

.content {
  padding-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.alert-div {
  padding: 30px 50px 0px 50px;
}
.alert-text {
  color: red;
  font-size: 14px;
  font-weight: 600;
}

.control-dots {
  visibility: hidden;
}

.numeric-pin-title {
  color: #000000;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 1.0px;
  margin: 0px 0px 24px 0px;
  line-height: 13px;
  display: block;
}

.numeric-pin {
  color: #000000;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 1.0px;
  margin: 0px 0px 48px 0px;
  line-height: 13px;
  display: block;
}